
import { ref, computed, watchEffect } from "vue";
import { useRouter } from "vue-router";

import { IonMenu, IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonList, IonLabel, IonIcon, menuController } from "@ionic/vue";

import {
  documents,
  time,
  person,
  clipboard,
  car,
  copy,
  ticket,
  wallet,
  card,
  people,
  book,
  informationCircle,
  documentText,
  listCircle,
  hourglass,
  newspaper,
  calendar,
  cube,
  home,
  list,
  barcode,
  shareSocial,
} from "ionicons/icons";

import { LOCALSTORAGE_KEY, config_menu, rolePermissions } from "@/custom_config.js";

import { getUser } from '@/services/userStore';

export default {
  components: {
      IonMenu,
      IonHeader,
      IonContent,
      IonToolbar,
      IonTitle,
      IonItem,
      IonList,
      IonLabel,
      IonIcon,
  },
  setup() {
      const router = useRouter();

      const userStr = localStorage.getItem(`${LOCALSTORAGE_KEY}`);

      const menu = ref(null);
              
      const userRole = ref(userStr ? JSON.parse(userStr).users_type_value : 'Admin');

      

      
     /*  watchEffect(() => {
          const user = localStorage.getItem(`${LOCALSTORAGE_KEY}`);
          if (user) {
              console.log(config_menu);

              const userData = JSON.parse(user);
              userRole.value = userData.users_type_value;  // Assicurati che il ruolo sia memorizzato correttamente
              console.log('userRole: ', userRole.value);
              
              console.log(menu.value);
              // Filtra il menu basato sul ruolo
              menu.value = config_menu.filter(item =>
                  item.show && item.allowedRoles.includes(userRole.value)
              );
              console.log(menu.value);
          }
      });

      const filteredMenuItems = computed(() => {
          console.log(userRole.value);
          
          if (!userRole.value) return [];
          return config_menu.filter(item =>
              item.show && (item.allowedRoles.includes(userRole.value))
          );
      }); */

      const filteredMenuItems = computed(() => {
          const user = getUser();
          //console.log('user: ', user);
          
          if (!user) return [];

          return config_menu.filter(item =>
              item.show && (item.allowedRoles.includes(user.users_type_value))
          );
      });

      //const userRole = ref(user.users_type_value);


      /* async function loadUserType() {
          const res = await apiUser.getInfo();
      } */

      //console.log($LOCALSTORAGEKEY);
      /*const { $LOCALSTORAGEKEY } = getCurrentInstance().appContext.config.globalProperties;
      console.log($LOCALSTORAGEKEY);*/

      // Filtro le voci del menu del custom_config in base ai permessi dell'utente (stessi del routing)
     /*  const filteredMenuItems = computed(() => {
          const permissions = rolePermissions[userRole.value] || [];
          return config_menu.filter(item => permissions.includes('*') || permissions.includes(item.route));
      }); */


      const changePage = (pageName) => {
          menuController.close("app-menu");
          router.push(`${pageName}`);
      };

      //Al decimo click mando al simulatore
      const counter = ref(0);

      const openSimulator = () => {
          counter.value++;

          if (counter.value === 10) {
              counter.value = 0; // reset counter
              menuController.close("app-menu");
              router.push("/simulatore");
          }
      };

      return {
          documents,
          time,
          person,
          clipboard,
          hourglass,
          car,
          router,
          copy,
          documentText,
          ticket,
          wallet,
          people,
          listCircle,
          card,
          book,
          newspaper,
          calendar,
          cube,
          informationCircle,
          home,
          list,
          barcode,
          shareSocial,
          changePage,
          openSimulator,
          //configMenu
          config_menu,
          filteredMenuItems,
          menu
      };
  },
};
