import {
    documents,
    time,
    person,
    clipboard,
    car,
    copy,
    ticket,
    wallet,
    card,
    people,
    book,
    informationCircle,
    documentText,
    listCircle,
    hourglass,
    newspaper,
    calendar,
    cube,
    home,
    list,
    barcode,
    shareSocial,
} from "ionicons/icons";

//Chiave localsotrage
const LOCALSTORAGE_KEY = `user${process.env.VUE_APP_LOCALSTORAGE_KEY}`;

// Pagina da vedere dopo il login
const pageAfterLogin = "presenze";

// Pulsanti presenti nella dashboard
const dashbordButtons = {
    presenze: true,
    documenti: true,
    richieste: true,
    automezzi: true,
    spese: true,
    tickets: true,
};

// Configurazione del menu
const config_menu = [
    {
        show: true,
        route: "presenze",
        icon: time,
        name: "Presenze",
        allowedRoles: ['Admin', 'Employee', 'Impiegati', 'Utenti APP', 'Capo Commessa', 'Assistente capo commessa']
    },
    {
        show: true,
        route: "documenti",
        icon: documents,
        name: "Documenti",
        allowedRoles: ['Admin', 'Employee', 'Impiegati', 'Utenti APP', 'Capo Commessa', 'Assistente capo commessa']
    },
    {
        show: true,
        route: "richieste",
        icon: clipboard,
        name: "Richieste",
        allowedRoles: ['Admin', 'Employee', 'Impiegati', 'Utenti APP', 'Capo Commessa', 'Assistente capo commessa']
    },
    {
        show: false,
        route: "banca",
        icon: hourglass,
        name: "Banca ore",
        allowedRoles: []
    },
    {
        show: false,
        route: "automezzi",
        icon: car,
        name: "Automezzi",
        allowedRoles: []
    },
    {
        show: false,
        route: "spese",
        icon: wallet,
        name: "Note spesa",
        allowedRoles: []
    },
    {
        show: false,
        route: "calendario",
        icon: calendar,
        name: "Calendario",
        allowedRoles: []
    },
    {
        show: false,
        route: "rimborsi",
        icon: card,
        name: "Rimborsi km",
        allowedRoles: []
    },
    {
        show: false,
        route: "clienti",
        icon: people,
        name: "Anagrafiche",
        allowedRoles: []
    },
    {
        show: false,
        route: "progetti",
        icon: listCircle,
        name: "Progetti",
        allowedRoles: []
    },
    {
        show: false,
        route: "leads",
        icon: book,
        name: "Leads",
        allowedRoles: []
    },
    {
        show: false,
        route: "interventi",
        icon: documentText,
        name: "Interventi",
        allowedRoles: []
    },
    {
        show: false,
        route: "rapportini",
        icon: documentText,
        name: "Rapportini",
        allowedRoles: []
    },
    {
        show: false,
        route: "tickets",
        icon: ticket,
        name: "Tickets",
        allowedRoles: []
    },
    {
        show: false,
        route: "task",
        icon: home,
        name: "Tasks",
        allowedRoles: []
    },
    {
        show: false,
        route: "timesheet",
        icon: time,
        name: "Timesheet",
        allowedRoles: []
    },
    {
        show: false,
        route: "bacheca",
        icon: shareSocial,
        name: "Bacheca",
        allowedRoles: []
    },
    {
        show: false,
        route: "programma",
        icon: calendar,
        name: "Programma lavoro",
        allowedRoles: []
    },
    {
        show: false,
        route: "ordini",
        icon: cube,
        name: "Ordini interni",
        allowedRoles: []
    },
    {
        show: false,
        route: "sondaggi",
        icon: newspaper,
        name: "Sondaggi",
        allowedRoles: []
    },
    {
        show: false,
        route: "magazzino",
        icon: barcode,
        name: "Magazzino",
        allowedRoles: []
    },
    {
        show: false,
        route: "todo",
        icon: list,
        name: "Liste",
        allowedRoles: []
    },
    {
        show: true,
        route: "profilo",
        icon: person,
        name: "Profilo",
        allowedRoles: ['Admin', 'Employee', 'Impiegati', 'Utenti APP', 'Capo Commessa', 'Assistente capo commessa']
    },
];

// Configurazione dei permessi per ruolo
const rolePermissions = {
    'Admin': ['*'], // '*' indica accesso a tutte le rotte
    'Capo Commessa': ['login', 'presenze', 'documenti', 'richieste', 'profilo'],
    'Assistente capo commessa': ['login', 'presenze', 'documenti', 'richieste', 'profilo'],
    'Impiegati': ['login', 'presenze', 'documenti', 'richieste', 'profilo'],
    'Utenti APP': ['login', 'presenze', 'documenti', 'richieste', 'profilo'],
    'Employee': ['login', 'presenze', 'documenti', 'richieste', 'banca ore', 'automezzi', 'Nota Spese', 'rimborsi km', 'interventi', 'tickets', 'tasks', 'programma lavoro', 'profilo'],
    'Sales Agent': ['login', 'presenze', 'documenti', 'richieste', 'automezzi', 'spese', 'calendario', 'clienti', 'progetti', 'leads', 'profilo'],
};

export { LOCALSTORAGE_KEY, pageAfterLogin, dashbordButtons, config_menu, rolePermissions };
